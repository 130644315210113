import React, { Component } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import classNames from "classnames";
import { AddButton } from "components/V2/Buttons";
import classnames from "classnames";
import { capitalize, get } from "lodash";
import { GooglePlacesService } from "services/index";
import moment from "moment";
import { rateTypeNameFinder } from "../../../helpers/rateTypeName";

const google = google || window.google;

class AddLocationModal extends Component {
  constructor(props) {
    super(props);
    this.addressRef = React.createRef();
    this.locationNameRef = React.createRef();
    this.state = {
      open: false,
      wasFieldValidated: {
        name: false,
        address: false,
      }
    };
  }

  toggle = () => {
    if (this.props.toggle) {
      this.props.toggle();
      return;
    }

    this.setState({ open: !this.state.open });
  };

  initAutocomplete = (e) => {
    const autocompleteOptions = { componentRestrictions: { country: "us" } };

    this.autocompleteAddress = new google.maps.places.Autocomplete(this.addressRef.current, autocompleteOptions);

    this.autocompleteLocationName = new google.maps.places.Autocomplete(
      this.locationNameRef.current,
      autocompleteOptions
    );

    this.autocompleteAddress.addListener("place_changed", () => {
      const places = this.autocompleteAddress.getPlace();
      this.retrieveLocation(places.place_id);
    });

    this.autocompleteLocationName.addListener("place_changed", () => {
      const places = this.autocompleteLocationName.getPlace();
      this.retrieveLocation(places.place_id);
    });
  };

  componentDidMount() {
    if (this.addressRef.current || this.locationNameRef.current) {
      this.initAutocomplete();
    }
  }

  componentDidUpdate() {
    if (
      (!this.autocompleteAddress && this.addressRef.current) ||
      (!this.autocompleteLocationName && this.locationNameRef.current)
    ) {
      this.initAutocomplete();
    }
  }

  onChangeLocation = ({ name, value }) => {
    this.props.parentSetState({
      ...this.props.shiftLocation,
      [name]: value,
    });
  };

  onFieldBlur = (field) => () => {
    if (!this.state.wasFieldValidated[field]) {
      this.setState((prevState) => {
        return {
          ...prevState,
          wasFieldValidated: {
            ...prevState.wasFieldValidated,
            [field]: true,
          },
        };
      });
    }
  };

  showError = (fieldName) => {
    const { wasValidated } = this.state;
    const { shiftLocation } = this.props;

    return (
      (wasValidated || get(this.state.wasFieldValidated, fieldName)) &&
      !!get(shiftLocation, `errorMessages[${fieldName}]`)
    );
  };

  retrieveLocation = (placeId) => {
    new GooglePlacesService(document.querySelector("#attribution"))
      .getDetails(placeId)
      .then((result) => {
        this.props.parentSetState({
          ...this.props.shiftLocation,
          ...result,
          address: {
            ...this.props.shiftLocation.address,
            ...result.address,
            id: null,
            isSearching: false,
          }
        });
      })
      .catch((error) => {
        console.error("Error retrieving location:", error);
      });
  };

  setPrecision = (amount) => Number(amount).toFixed(2);

  formIsValid = () => !!this.props.shiftLocation.placeId;

  onClick = () => {
    this.props.onSave();
    this.toggle()
  }

  render() {
    const { job, shift, rateTypes, shiftLocation } = this.props;
    const startTime = moment(job.startTime);
    const endTime = moment(job.endTime);
    const rateTypeName = rateTypeNameFinder(rateTypes, job.rateTypeId);

    return (
      <div className={classNames("v2-add-location-button")}>
        {this.props.children && (
          <AddButton className={"add-location"} onClick={this.toggle}>
            {this.props.children}
          </AddButton>
        )}
        <div className={classNames("v2-add-location-modal")} style={{ position: "absolute" }}>
          <Modal isOpen={this.state.open} toggle={this.toggle} className="v2-add-location-modal-content">
            <ModalHeader className="header" toggle={this.toggle}>
              Add Location
            </ModalHeader>
            <ModalBody>
              <div className="row">
                <div className="form-group col-md-12">
                  <label htmlFor="name">Location Name</label>
                  <input
                    type="text"
                    name="name"
                    ref={this.locationNameRef}
                    value={shiftLocation.name}
                    disabled={false}
                    onChange={(e) =>
                      this.onChangeLocation({
                        name: "name",
                        value: e.target.value,
                      })
                    }
                    onBlur={this.onFieldBlur("name")}
                    className={classnames("form-control italic-placeholder nimbus-regular-font", {
                      "is-invalid": this.showError("name"),
                    })}
                    placeholder="What is the commonly used name for this location."
                  />
                  <div className="invalid-feedback nimbus-regular-font">{get(shiftLocation, "errorMessages.name")}</div>
                </div>
              </div>
              <div className="row countType">
                <div className="form-group col-md-12">
                  <label htmlFor="address">Address</label>
                  <input
                    name="address"
                    className={classnames("form-control italic-placeholder nimbus-regular-font", {
                      "is-invalid": this.showError("address"),
                    })}
                    placeholder="Start typing and choose from the suggestions."
                    ref={this.addressRef}
                    value={shiftLocation.formattedAddress}
                    disabled={false}
                    autoComplete="off"
                    onChange={(e) =>
                      this.onChangeLocation({
                        name: "formattedAddress",
                        value: e.target.value,
                      })
                    }
                    onBlur={this.onFieldBlur("address")}
                  />
                  <div className="invalid-feedback nimbus-regular-font">
                    {get(shiftLocation, "errorMessages.address")}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <ul>This new location will use the existing job's:</ul>
                    <ul>
                      <li>Shift Date: {startTime.format("MM/DD/YYYY")}</li>
                      <li>
                        Shift Times: {startTime.format("h:mm A")} - {endTime.format("h:mm A")}
                      </li>
                      <li>
                        Pay Rate: ${this.setPrecision(job.payRate)}/{capitalize(rateTypeName)}{" "}
                      </li>
                      <li>
                        Job Contact:
                        {shift.reportToContacts.map((contact) => (
                          <div key={contact.id}>- {[contact.name, contact.email, contact.phoneNumber].join(", ")}</div>
                        ))}
                      </li>
                    </ul>
                    <ul>
                      This new location’s Supplies, Details, and Emergency Action Plan (EAP) will all be blank by
                      default.
                    </ul>
                  </div>
                </div>
              </div>
              <div className="p-0 m-0" id="attribution"></div>
            </ModalBody>
            <ModalFooter className="actions">
              <button className="btn confirm-btn" onClick={this.toggle}>
                CANCEL
              </button>
              <button
                className={classNames("btn btn-success", {
                  disabled: !this.formIsValid(),
                })}
                disabled={!this.formIsValid()}
                onClick={this.onClick}
              >
                SAVE
              </button>
            </ModalFooter>
          </Modal>
        </div>
      </div>
    );
  }
}

export default AddLocationModal;
